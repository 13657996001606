import { ProductCard } from '$shared/components';
import { memo, useEffect } from 'react';
import useCustomer from '~/features/commerce-api/hooks/useCustomer';
import { useWishlist } from '~/features/commerce-api/hooks/useWishlist';
import { M31RaptorProductCarouselModule as M31RaptorCarouselModuleType } from '~/lib';
import { useRaptorProducts } from '~/services/raptor/useRaptorProducts';
import { useProductHandler } from '~/services/search/useProductHandler';
import { CardCarousel } from '~/shared/components/CardCarousel/CardCarousel';
import ContentLoader from 'react-content-loader';
import { useEvents } from '~/shared/hooks/useEvents';
import { useFrame } from '~/shared/utils';
import { ModuleContainer } from '../ModuleContainer';
import { _transformProductDetailsToDataLayer } from '~/shared/hooks/useEvents/helpers';
import { TProductDetails } from '~/shared/components/ProductCard/ProductCard.definition';

export type M31Props = M31RaptorCarouselModuleType;

const M31RaptorProductCarouselBlockComponent = ({
    headline,
    subHeadline,
    callToAction,
    priority,
    raptorAlgorithm,
    raptorParameter,
    productId,
    productBrand,
    productCategory,
    merchandisingCampaignId,
    ...rest
}: M31Props) => {
    const { data: frame } = useFrame();
    const { productsImpressionsEvent } = useEvents(frame);
    const {
        data: products,
        isSuccess,
        isError,
        isLoading,
    } = useRaptorProducts({
        config: raptorAlgorithm,
        param: raptorParameter,
        productId: productId,
        productBrand: productBrand,
        productCategory: productCategory,
        merchandisingCampaignId: merchandisingCampaignId,
    });
    const { isPlusMember } = useCustomer();
    const productHandler = useProductHandler();
    const { handleFavourite, isFavorite } = useWishlist();

    useEffect(() => {
        if (products && products.length > 0) {
            productsImpressionsEvent(
                raptorAlgorithm,
                raptorAlgorithm,
                products?.map((item: TProductDetails, index: number) =>
                    _transformProductDetailsToDataLayer(item, 1, index, isPlusMember),
                ),
            );
        }
    }, [products]);

    if (isError) {
        return null;
    }

    if (!isSuccess && isLoading) {
        return (
            <ModuleContainer {...rest}>
                <CardCarousel
                    options={{
                        slidesPerView: 'auto',
                        spaceBetween: 24,
                    }}
                    headline={headline}
                    subHeadline={subHeadline}
                    items={[...Array(5)].map(() => (
                        <ContentLoader
                            speed={1}
                            width="100%"
                            height="100%"
                            viewBox="0 0 350 600"
                            backgroundColor="#f5f5f5"
                            foregroundColor="#dbdbdb"
                        >
                            <rect x="0" y="0" rx="0" ry="0" width="100%" height="342" />
                            <rect x="76" y="400" rx="0" ry="0" width="198" height="10" />
                            <rect x="42" y="425" rx="0" ry="0" width="266" height="10" />
                            <rect x="76" y="450" rx="0" ry="0" width="198" height="10" />
                            <rect x="29" y="525" rx="6" ry="6" width="292" height="48" />
                        </ContentLoader>
                    ))}
                ></CardCarousel>
            </ModuleContainer>
        );
    }

    return isSuccess && products && products?.length > 0 ? (
        <ModuleContainer {...rest}>
            <CardCarousel
                options={{
                    slidesPerView: 'auto',
                    spaceBetween: 24,
                }}
                headline={headline}
                subHeadline={subHeadline}
                callToAction={callToAction}
                items={products?.map((p: TProductDetails, i: number) => (
                    <ProductCard
                        {...p}
                        priority={priority}
                        position={i}
                        listIdentifier={'Raptor - ' + raptorAlgorithm}
                        onBuyBtn={productHandler}
                        onFavourite={handleFavourite}
                        isFavourite={isFavorite(p.id)}
                        raptorAlgorithm={raptorAlgorithm}
                    />
                ))}
            />
        </ModuleContainer>
    ) : null;
};

export const M31RaptorProductCarouselBlock = memo(M31RaptorProductCarouselBlockComponent);
